import { graphql, StaticQuery } from 'gatsby';
import React from 'react';

import Menu from '.';

const MenuData = () => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            menuLinks {
              name
              link
            }
            contact {
              phone
            }
          }
        }
      }
    `}
    render={data => <Menu data={data} />}
  />
);

export default MenuData;

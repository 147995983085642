import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Footer from '.';

const FooterData = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            author
            authorWebsite
          }
        }
      }
    `}
    render={data => <Footer data={data} />}
  />
);

export default FooterData;

import styled from 'styled-components';
import color from '../../constants/colors';
import breakpoints from '../../constants/breakpoints';

const HamburgerInner = styled.span`
  display: block;
  top: 50%;
  margin-top: -1.5px;
  width: 2rem;
  height: 0.3rem;
  background-color: ${color.orange};
  border-radius: 0.3rem;
  position: absolute;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  &::before,
  &::after {
    width: 3rem;
    height: 0.3rem;
    background-color: ${color.orange};
    border-radius: 0.3rem;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.4s;
    transition-timing-function: ease;
  }
  &::before,
  &::after {
    content: "";
    display: block;
  }
  &::before {
    top: -8px;
  }
  &::after {
    bottom: -8px;
  }

  &::before {
    top: -1rem;
    transition-timing-function: ease;
    transition-duration: 0.4s;
  }

  &::after {
    top: 1rem;
  }
`;

const StyledHamburger = styled.button`
  position: fixed;
  right: 2rem;
  top: 1rem;
  padding: 0;
  padding-top: 10px;
  outline: none;
  z-index: 10;
  cursor: pointer;
  width: 60px;
  height: 60px;
  display: inline-block;
  transition-duration: 0.4s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &.is-active .hamburger-inner,
  &.is-active::before,
  &.is-active::after {
    background-color: ${color.white};
  }

  &.is-active {
    ${HamburgerInner} {
      background: transparent;
    }

    ${HamburgerInner}::before,
    ${HamburgerInner}::after {
      background: ${color.white};
      width: 32px;
    }

    ${HamburgerInner}::before {
      -webkit-transform: rotate(-45deg) translate3d(-4.57143px, -5px, 0);
      transform: rotate(-45deg) translate3d(-8px, 3px, -2px);
      background: ${color.white};
    }
    ${HamburgerInner}::after {
      -webkit-transform: translate3d(0, 8px, 0) rotate(45deg);
      transform: translate3d(-2px, -12px, -2px) rotate(45deg);
      background: ${color.white};
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

const HamburgerBox = styled.span`
  width: 3.5rem;
  height: 3.5rem;
  display: inline-block;
  position: relative;
`;

export { StyledHamburger, HamburgerBox, HamburgerInner };

import styled from 'styled-components';
import color from '../../constants/colors';
import breakpoints from '../../constants/breakpoints';

const HeaderStyled = styled.div`
  height: 80px;
  color: ${color.orange};
  background-color: ${color.white};
  box-shadow: 0 1px 15px rgba(50, 50, 93, 0.2);
`;

const HeaderContainer = styled.div`
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderPhone = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  left: 30%;
  a {
    color: ${color.orange};
    font-size: 1.4rem;
    font-weight: 600;
    text-decoration: none;
    @media (min-width: ${breakpoints.sm}) {
      font-size: inherit;
    }
  }
  img {
    margin: 0 0.5rem;
    width: 3rem;
  }
  @media (min-width: ${breakpoints.sm}) {
    left: 45%;
  }
  @media (min-width: ${breakpoints.md}) {
    left: 40%;
  }
  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

const HeaderLogo = styled.div`
  display: block;
  width: 8rem;
  @media (min-width: ${breakpoints.sm}) {
    width: 11.5rem;
  }
  img {
    width: 100%;
    height: 6.8rem;
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export {
  HeaderStyled, HeaderContainer, HeaderPhone, HeaderLogo,
};

import React from 'react';
import Helmet from 'react-helmet';
import favicon from '../../../../static/favicon.ico';

const SEO = ({ data }) => {
  const { title } = data.site.siteMetadata;
  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={title}
      titleTemplate={`%s - ${data.site.siteMetadata.description}`}
      link={[
        {
          rel: 'shortcut icon',
          type: 'image/png',
          href: `${favicon}`,
        },
      ]}
    />
  );
};

SEO.defaultProps = {
  lang: 'pl',
  meta: [],
  keywords: [],
};

export default SEO;

import React from 'react';
import { Link } from 'gatsby';
import { StyledMenuMobile, MenuMobileUl, MenuMobileLi } from '../styles';

const MenuMobile = ({ data, active }) => {
  const { menuLinks } = data.site.siteMetadata;
  return (
    <StyledMenuMobile className={`${active ? 'open' : ''}`}>
      <MenuMobileUl>
        {menuLinks.map(link => (
          <MenuMobileLi key={link.name}>
            <Link to={link.link}>{link.name}</Link>
          </MenuMobileLi>
        ))}
        <MenuMobileLi>
          <a href={`tel:${data.site.siteMetadata.contact.phone}`}>
            {data.site.siteMetadata.contact.phone}
          </a>
        </MenuMobileLi>
      </MenuMobileUl>
    </StyledMenuMobile>
  );
};

export default MenuMobile;

import styled from 'styled-components';
import color from '../../constants/colors';

const MenuMobileUl = styled.ul`
  font-size: 2rem;
  text-align: center;
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 0;
`;

const MenuMobileLi = styled.li`
  font-size: 2.6rem;
  display: block;
  position: relative;
  opacity: 0;
  padding: 1.2rem;

  a {
    display: block;
    position: relative;
    color: ${color.white};
    text-decoration: none;
    overflow: hidden;

    &:hover:after,
    &:focus:after,
    &:active:after {
      width: 100%;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      transform: translateX(-50%);
      height: 3px;
      background: ${color.orange};
      transition: 0.35s;
    }
  }
`;

const StyledMenuMobile = styled.div`
  position: fixed;
  background: ${color.orange};
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &.open {
    opacity: 0.9;
    visibility: visible;
    height: 100%;
    z-index: 10;

    ${MenuMobileLi} {
      animation: fadeInRight 0.5s ease forwards;
      animation-delay: 0.35s;

      &:nth-of-type(2) {
        animation-delay: 0.4s;
      }

      &:nth-of-type(3) {
        animation-delay: 0.5s;
      }

      &:nth-of-type(4) {
        animation-delay: 0.6s;
      }

      &:nth-of-type(5) {
        animation-delay: 0.7s;
      }

      &:nth-of-type(6) {
        animation-delay: 0.8s;
      }
    }
  }

  @keyframes fadeInRight {
    0% {
      opacity: 0;
      left: 20%;
    }
    100% {
      opacity: 1;
      left: 0;
    }
  }
`;

export { StyledMenuMobile, MenuMobileUl, MenuMobileLi };

import styled from 'styled-components';
import breakpoints from '../../constants/breakpoints';
import color from '../../constants/colors';

const MainMenu = styled.div`
  display: none;
  @media (min-width: ${breakpoints.lg}) {
    display: block;
  }
`;

const MainMenuUl = styled.ul`
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style: none;
`;

const MainMenuLi = styled.li`  
    margin: 0 0.5rem;
    padding: 0;
    list-style: none;     
   &:last-of-type {
    display: inline-flex;
    height: 3.6rem;    
  }
   > a {
    font-size: 1.6rem;
    font-weight: 600; 
    text-align: center;
    display: inline-block;   
    text-transform: uppercase;
    color: inherit;
    padding: 10px 14px 10px 14px;
    text-decoration: none;
    -webkit-letter-spacing: 0.025em;
    -moz-letter-spacing: 0.025em;
    -ms-letter-spacing: 0.025em;
    letter-spacing: 0.025em;
    -webkit-text-decoration: none;    
    -webkit-transition: all 0.4s ease;   
    transition: all 0.4s;
  }

   > a:hover {  
    text-decoration: underline;   
    -webkit-transform: translateX(-0.4rem);    
    -ms-transform: translateX(-0.4rem);
    transform: translateX(-0.4rem);    
  }
  
   &:last-child > a:hover {
    background: none;
    color: unset;
  }
`;

const CurrentPage = {
  color: `${color.grey}`,
  textDecoration: 'underline',
};


export {
  MainMenu, MainMenuUl, MainMenuLi, CurrentPage,
};

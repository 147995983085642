import styled from 'styled-components';
import color from '../../constants/colors';
import breakpoints from '../../constants/breakpoints';

const FooterStrip = styled.div`
  background: ${color.grey};
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
`;

const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  @media (min-width: ${breakpoints.sm}) {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
`;

const FooterUl = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  flex: 1 1;
  @media (min-width: ${breakpoints.sm}) {
    height: inherit;
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: flex-end;
  }
`;

const FooterLi = styled.li`
  display: block;
  &:last-child a {
    color: ${color.white};
    font-weight: 700;
  }
  a {
    color: ${color.orange};
    font-size: 1.4rem;
    font-weight: 500;
    display: inline-block;
    text-decoration: unset;
    text-align: center;
  }
  @media (min-width: ${breakpoints.sm}) {
    padding: 0.8rem;
  }
  a:hover {
    color: ${color.orange};
  }
`;

const SocialIcon = styled.a`
  text-decoration: none;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  &:hover {
    filter: opacity(90%);
    -webkit-transform: translateX(-0.4rem);
    transform: translateX(-0.4rem);
  }
`;

export {
  FooterStrip, StyledFooter, FooterUl, FooterLi, SocialIcon,
};

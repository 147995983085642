import React from 'react';
import { Link } from 'gatsby';
import { Col, Container, Row } from 'react-grid-system';
import { fb } from '../../../assets/icons';
import {
  FooterStrip,
  StyledFooter,
  FooterUl,
  FooterLi,
  SocialIcon,
} from '../styles';

const Footer = ({ data }) => (
  <FooterStrip>
    <Container>
      <Row>
        <Col lg={12}>
          <StyledFooter>
            <FooterLi>
              <SocialIcon
                href="https://www.facebook.com/Radtke-Transport-443420559546170"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={fb} alt="fb-icon" />
              </SocialIcon>
            </FooterLi>

            <FooterUl>
              <FooterLi>
                <Link to="/">Strona główna</Link>
              </FooterLi>
              <FooterLi>
                <Link to="/about">O nas</Link>
              </FooterLi>
              <FooterLi>
                <Link to="/fleet">Nasza Flota</Link>
              </FooterLi>
              <FooterLi>
                <Link to="/career">Kariera</Link>
              </FooterLi>
              <FooterLi>
                <Link to="/contact">Kontakt</Link>
              </FooterLi>
              <FooterLi>
                <a href={data.site.siteMetadata.authorWebsite}>
                  ©
                  {''}
                  {' '}
                  {new Date().getFullYear()}
                  {''}
                  {' '}
                  {data.site.siteMetadata.author}
                </a>
              </FooterLi>
            </FooterUl>
          </StyledFooter>
        </Col>
      </Row>
    </Container>
  </FooterStrip>
);

export default Footer;

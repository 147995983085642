import styled from 'styled-components';
import color from '../../constants/colors';
import breakpoints from '../../constants/breakpoints';

const SubFooterStrip = styled.div`
  background: ${color.black};
  padding: 1.2rem 0;
`;

const StyledSubFooter = styled.div`
  display: block;
  justify-content: space-between;
  @media (min-width: ${breakpoints.sm}) {
    display: flex;
  }
`;
const SubFooterUl = styled.div`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const SubFooterLi = styled.div`
  list-style: none;
  display: block;
  font-size: 1.4rem;
  padding-bottom: 0.5rem;
  color: ${color.white};
  span {
    font-weight: bold;
  }
  a {
    color: ${color.white};
    text-decoration: none;
  }
  a:hover {
    color: ${color.white};
  }
`;

export {
  SubFooterStrip, StyledSubFooter, SubFooterUl, SubFooterLi,
};

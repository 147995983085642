import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import Header from '.';

const HeaderData = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            contact {
              phone
            }
          }
        }
      }
    `}
    render={data => <Header data={data} />}
  />
);

export default HeaderData;

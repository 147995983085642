import React from 'react';
import Helmet from 'react-helmet';
import SEO from './SEO/components/SEOData';
import Header from './Header/components/data';
import Footer from './Footer/components/data';
import SubFooter from './SubFooter/components/data';
import Global from './Global';

const Layout = ({ children }) => (
  <>
    <SEO title="Radtke Transport" />
    <Helmet
      link={[
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css?family=Maven+Pro&display=swap',
        },
      ]}
    >
      <meta name="description" content="Radtke Transport. Transport chłodniczy." />
      <meta name="google-site-verification" content="BhLWgwiL-yAGIG5Ogp9TKICA-ykMe_Tms6mxtMVL9TU" />
    </Helmet>
    <Global />
    <Header />
    {children}
    <Footer />
    <SubFooter />
  </>
);

export default Layout;

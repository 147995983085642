import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import MenuMobile from '.';

const MenuMobileData = (props) => {
  const { active } = props;
  return (
    <StaticQuery
      query={graphql`
        query MenuMobileQuery {
          site {
            siteMetadata {
              menuLinks {
                name
                link
              }
              contact {
                phone
              }
            }
          }
        }
      `}
      render={data => <MenuMobile active={active} data={data} />}
    />
  );
};
export default MenuMobileData;

import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Container } from 'react-grid-system';
import Menu from '../../Menu/components/data';
import MenuMobile from '../../MenuMobile/components/data';
import Hamburger from '../../Hamburger/components';
import { phone, logo } from '../../../assets/icons';
import {
  HeaderStyled, HeaderContainer, HeaderPhone, HeaderLogo,
} from '../styles';

const Header = ({ data }) => {
  const [headerState, headerSetState] = useState({
    menuActive: false,
  });

  const toggleMenu = () => {
    headerSetState(prevState => ({
      menuActive: !prevState.menuActive,
    }));
  };

  return (
    <HeaderStyled>
      <Container>
        <HeaderContainer>
          <HeaderLogo>
            <Link to="/">
              <img alt="logo" src={logo} />
            </Link>
          </HeaderLogo>
          <HeaderPhone>
            <img src={phone} alt="" />
            <a href={`tel:${data.site.siteMetadata.contact.phone}`}>
              {data.site.siteMetadata.contact.phone}
            </a>
          </HeaderPhone>
          <MenuMobile active={headerState.menuActive} />
          <Menu />
          <Hamburger toggleMenu={toggleMenu} />
        </HeaderContainer>
      </Container>
    </HeaderStyled>
  );
};

export default Header;

import React, { useState } from 'react';
import { StyledHamburger, HamburgerBox, HamburgerInner } from '../styles';

const Hamburger = (props) => {
  const [hamburgerState, setHamburgerState] = useState({
    hamburgerActive: false,
  });

  const handleToggle = () => {
    setHamburgerState(prevState => ({
      hamburgerActive: !prevState.hamburgerActive,
    }));
    props.toggleMenu(hamburgerState.hamburgerActive);
  };

  return (
    <StyledHamburger
      className={`${StyledHamburger} ${
        hamburgerState.hamburgerActive ? 'is-active' : ''}`}
      type="button"
      onClick={handleToggle}
    >
      <HamburgerBox>
        <HamburgerInner />
      </HamburgerBox>
    </StyledHamburger>
  );
};

export default Hamburger;

import React from 'react';
import { Link } from 'gatsby';
import { phone } from '../../../assets/icons';
import {
  MainMenu, MainMenuUl, MainMenuLi, CurrentPage,
} from '../styles';

const Menu = ({ data }) => {
  const { menuLinks } = data.site.siteMetadata;
  return (
    <MainMenu>
      <MainMenuUl>
        {menuLinks.map(link => (
          <MainMenuLi key={link.name}>
            <Link to={link.link} activeStyle={CurrentPage}>
              {link.name}
            </Link>
          </MainMenuLi>
        ))}
        <MainMenuLi>
          <img src={phone} alt="" />
          <a href={`tel:${data.site.siteMetadata.contact.phone}`}>
            {data.site.siteMetadata.contact.phone}
          </a>
        </MainMenuLi>
      </MainMenuUl>
    </MainMenu>
  );
};

export default Menu;

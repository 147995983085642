import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import SubFooter from '.';

const SubFooterData = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            contact {
              email
              phone
              website
            }
          }
        }
      }
    `}
    render={data => <SubFooter data={data} />}
  />
);

export default SubFooterData;

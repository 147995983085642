import React from 'react';
import { Col, Container, Row } from 'react-grid-system';

import {
  SubFooterStrip,
  StyledSubFooter,
  SubFooterUl,
  SubFooterLi,
} from '../styles';

const SubFooter = ({ data }) => (
  <SubFooterStrip>
    <Container>
      <Row>
        <Col lg={12}>
          <StyledSubFooter>
            <SubFooterUl>
              <SubFooterLi>
                <span>Telefon: </span>
                {data.site.siteMetadata.contact.phone}
              </SubFooterLi>
              <SubFooterLi>
                <span>Email: </span>
                <a href={`mailto:${data.site.siteMetadata.contact.email}`}>
                  {data.site.siteMetadata.contact.email}
                </a>
              </SubFooterLi>
            </SubFooterUl>
            <SubFooterUl>
              <SubFooterLi>
                <a href={data.site.siteMetadata.contact.website}>
                  {data.site.siteMetadata.contact.website}
                </a>
              </SubFooterLi>
            </SubFooterUl>
          </StyledSubFooter>
        </Col>
      </Row>
    </Container>
  </SubFooterStrip>
);

export default SubFooter;

import phone from './phone.svg';
import logo from './logo.svg';
import fb from './facebook.svg';
import pin from './location-pin.svg';
import close from './close.svg';
import rightArrow from './right-arrow.svg';
import checkmark from './checkmark.svg';
import tick from './tick.svg';
import circle from './circle.svg';
import loader from './loader.svg';
import send from './send.svg';
import arrows from './arrows.svg';

export {
  phone, logo, fb, pin, close, rightArrow, checkmark, tick, circle, loader, send, arrows,
};

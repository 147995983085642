import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import SEO from './SEO';

const SEOData = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={data => <SEO data={data} />}
  />
);

export default SEOData;
